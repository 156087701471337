import { Options, RenderText } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import dynamic from 'next/dynamic';
import { Key } from 'react';

import SafeAnchorWithAmplitudeEvent from '@tb-core/components/behavior/safe-anchor/with-amplitude-event';
import ContentfulRichText, {
    ContentfulRichTextProps
} from '@tb-core/components/simple/contentful-rich-text';
import { HyperLinkProps } from '@tb-core/components/simple/contentful-rich-text-with-ga-event';
import { ContentfulRichLinkProps } from '@tb-core/helpers/adapters/contentful/rich-text-props-adapter';
import mapLinkedEntries from '@tb-core/helpers/contentful/map-linked-entries';
import { ColorThemeProps, JsxChildren, RealObject } from '@tb-core/types';

const Heading = dynamic(() => import('@tb-core/components/simple/heading'));
const Image = dynamic(() => import('@tb-core/components/styled/image'));
const NutritionXIframe = dynamic(() =>
    import('@tb-core/components/styled/nutritionx-iframe')
);
const OneTrustEmbed = dynamic(() =>
    import('@tb-core/components/styled/onetrust-embed')
);
const SpotifyPodcastIframe = dynamic(() =>
    import('@tb-core/components/styled/spotify-podcast-iframe')
);
const VideoPlayer = dynamic(() =>
    import('@tb-core/components/styled/video-player')
);

const toID = (children: React.ReactNode) => {
    let arr;
    if (Array.isArray(children)) {
        arr = children.flat().map(ele => {
            return typeof ele === 'string' ? ele : '_';
        });
    }
    const str = String(arr || children);

    return slugify(str);
};

export const stripTags = (str: string) => str.replace(/(<([^>]+)>)/gi, '');

export const slugify = (val: string) => {
    // trim all leading/trailing slashes and spaces
    let str = val.replace(/^[\s+\/]+|[\s+\/]+$/g, '').toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
};

interface ContentfulRichTextWithThemeProps extends ContentfulRichTextProps {
    colorTheme?: ColorThemeProps | null;
    linkedEntries?: ContentfulRichLinkProps;
}

/**
 * locate tokens wrapped in currly braces
 * and replace the matching key in `tokenNodes` object
 * ex: {copy} will be replaced with <sup>&copy;</sup>
 */
const renderRichTextMarks: RenderText = text => {
    return text
        .split(/({[^{}]*})/g)
        .reduce(
            (children: any, textSegment: string, index: number) => [
                ...children,
                'textSegment' in tokenNodes
                    ? tokenNodes[textSegment](index + textSegment)
                    : textSegment
            ],
            []
        );
};

/**
 * tokens as react nodes
 * non-exported memnber
 * used by the above `renderRichText` fn which is pass as
 * the `renderText` option of ContentfulRichText component
 */
const tokenNodes: Record<string, (key: Key) => React.ReactNode> = {
    '\n': key => <br key={key} />,
    '{1}': key => <sup key={key}>1</sup>,
    '{2}': key => <sup key={key}>2</sup>,
    '{3}': key => <sup key={key}>3</sup>,
    '{4}': key => <sup key={key}>4</sup>,
    '{5}': key => <sup key={key}>5</sup>,
    '{6}': key => <sup key={key}>6</sup>,
    '{7}': key => <sup key={key}>7</sup>,
    '{8}': key => <sup key={key}>8</sup>,
    '{9}': key => <sup key={key}>9</sup>,
    '{asterisk}': key => <sup key={key}>&#42;</sup>,
    '{copysub}': key => <sub key={key}>&copy;</sub>,
    '{copy}': key => <sup key={key}>&copy;</sup>,
    '{dagger}': key => <sup key={key}>&dagger;</sup>,
    '{delta}': key => <sup key={key}>&Delta;</sup>,
    '{doubledagger}': key => <sup key={key}>&Dagger;</sup>,
    '{downarrow}': key => <sup key={key}>&darr;</sup>,
    '{lozenge}': key => <sup key={key}>&#9674;</sup>,
    '{numbersign}': key => <sup key={key}>&#35;</sup>,
    '{pilcrow}': key => <sup key={key}>&#8267;</sup>,
    '{regsub}': key => <sub key={key}>&reg;</sub>,
    '{reg}': key => <sup key={key}>&reg;</sup>,
    '{section}': key => <sup key={key}>&sect;</sup>,
    '{tradesub}': key => <sub key={key}>&trade;</sub>,
    '{trade}': key => <sup key={key}>&trade;</sup>,
    '{vertical}': key => <sup key={key}>&#8214;</sup>
};

/**
 * tokens for use with CTF strings values
 * exported
 * result intended to be sent to `dangerouslySetInnerHtml`
 */
const tokenStrings: RealObject = {
    1: '<sup>1</sup>',
    2: '<sup>2</sup>',
    3: '<sup>3</sup>',
    4: '<sup>4</sup>',
    5: '<sup>5</sup>',
    6: '<sup>6</sup>',
    7: '<sup>7</sup>',
    8: '<sup>8</sup>',
    9: '<sup>9</sup>',
    asterisk: <sup>&#42;</sup>,
    copy: '<sup>&copy;</sup>',
    copysub: '<sub>&copy;</sub>',
    dagger: '<sup>&dagger;</sup>',
    delta: '<sup>&Delta;</sup>',
    doubledagger: '<sup>&Dagger;</sup>',
    downarrow: '<sup>&darr;</sup>',
    lozenge: '<sup>&#9674;</sup>',
    numbersign: '<sup>&#35;</sup>',
    pilcrow: '<sup>&#8267;</sup>',
    reg: '<sup>&reg;</sup>',
    regsub: '<sub>&reg;</sub>',
    section: '<sup>&sect;</sup>',
    trade: '<sup>&trade;</sup>',
    tradesub: '<sub>&trade;</sub>',
    vertical: '<sup>&#8214;</sup>'
};

const ContentfulRichTextWithTheme = ({
    linkedEntries,
    node,
    colorTheme,
    renderOptions
}: ContentfulRichTextWithThemeProps) => {
    const webLinkTheme = {
        color: colorTheme?.webLink
    };
    let linkedEntryMap: Record<string, any>;
    const options: Options = {
        // preserveWhitespace: true,
        renderMark: {
            [MARKS.SUPERSCRIPT]: text => <sup>{text}</sup>,
            [MARKS.SUBSCRIPT]: text => <sub>{text}</sub>
        },
        renderNode: {
            [BLOCKS.HEADING_2]: (_, children) => (
                <Heading tag="h2" id={toID(children)}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_3]: (_, children) => (
                <Heading tag="h3" id={toID(children)}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_4]: (_, children) => (
                <Heading tag="h4" id={toID(children)}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_5]: (_, children) => (
                <Heading tag="h5" id={toID(children)}>
                    {children}
                </Heading>
            ),
            [BLOCKS.HEADING_6]: (_, children) => (
                <Heading tag="h6" id={toID(children)}>
                    {children}
                </Heading>
            ),
            [INLINES.HYPERLINK]: ({
                data,
                content
            }: HyperLinkProps): JsxChildren => {
                const { uri } = data;
                const { value = '' } = content[0];
                return (
                    <SafeAnchorWithAmplitudeEvent
                        eventInput="Clicked Inline Link"
                        href={uri}
                        style={webLinkTheme}
                        eventProperties={{
                            link_destination: uri,
                            link_text: value
                        }}
                    >
                        {value}
                    </SafeAnchorWithAmplitudeEvent>
                );
            },
            [BLOCKS.EMBEDDED_ENTRY]: node => {
                linkedEntryMap =
                    linkedEntryMap || mapLinkedEntries(linkedEntries);
                const entry =
                    linkedEntryMap.entryMap.get(node.data.target.sys.id) || {};

                switch (entry.__typename) {
                    case 'Image':
                        const alignmentClassName =
                            'alignment' in entry &&
                            ['Left', 'Right', 'Center'].includes(
                                entry.alignment
                            )
                                ? `align-${entry.alignment.toLowerCase()}`
                                : '';
                        return (
                            <div
                                className={`media-embed-block image-embed ${alignmentClassName}`}
                            >
                                <Image {...entry} />
                            </div>
                        );
                    case 'VideoPlayer':
                        return (
                            <div className="media-embed-block video-embed">
                                <VideoPlayer {...entry} />
                            </div>
                        );
                    case 'IFrameModule':
                        if (entry.type === 'NutritionX') {
                            return <NutritionXIframe {...entry} />;
                        } else if (entry.type === 'Spotify Podcast') {
                            return <SpotifyPodcastIframe {...entry} />;
                        }
                }
            },
            [INLINES.EMBEDDED_ENTRY]: node => {
                linkedEntryMap =
                    linkedEntryMap || mapLinkedEntries(linkedEntries);
                const entry =
                    linkedEntryMap.entryMap.get(node.data.target.sys.id) || {};

                switch (entry.__typename) {
                    case 'Image':
                        const alignmentClassName =
                            'alignment' in entry &&
                            ['Left', 'Right', 'Center'].includes(
                                entry.alignment
                            )
                                ? `align-${entry.alignment.toLowerCase()}`
                                : '';
                        return (
                            <span
                                className={`media-embed-inline image-embed ${alignmentClassName}`}
                            >
                                <Image {...entry} />
                            </span>
                        );
                    case 'OneTrustEmbed':
                        return <OneTrustEmbed type={entry.type} />;
                }
            }
        }
    };

    if (renderOptions?.renderText) {
        options.renderText = renderOptions.renderText;
    }

    return <ContentfulRichText node={node} renderOptions={options} />;
};

export default ContentfulRichTextWithTheme;
export { tokenStrings, renderRichTextMarks };
