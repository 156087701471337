import { track } from '@amplitude/analytics-browser';

import { setAmplitudeUserStatus } from '@tb-core/helpers/analytics/amplitude';
import { RealObject } from '@tb-core/types';

export const mapDataLayerToAmplitudeProps = (data: Record<string, any>) => ({
    // FIXME splitting on the dash seems to be unrelaible action as dash are port of normal heading text
    header_text: (data.gaDataLayerConfig['Analytics-Value'] || '').split(
        '-'
    )[0],
    module_type: data.gaDataLayerConfig['Analytics-Action'],
    ...(data.gaDataLayerConfig.entry_title && {
        entry_title: data.gaDataLayerConfig.entry_title
    }),
    ...(data.gaDataLayerConfig.page_title && {
        page_title: data.gaDataLayerConfig.page_title
    })
});

/**
 *
 * Takes data and pagePath from brandedCtaGroup
 * to create clicked module event for amplitude
 *
 */

const setModuleAmplitudeEvent = (data: RealObject, url: string) => {
    const ampProps = {
        ...mapDataLayerToAmplitudeProps(data),
        page_path: url,
        ...setAmplitudeUserStatus(data.isLoggedIn),
        ...(data.label && { cta: data.label })
    };
    track('Clicked Module', ampProps);
};

export default setModuleAmplitudeEvent;
