import dynamic from 'next/dynamic';

import usePageContext from '@tb-core/hooks/use-page-context';
import useUserContext from '@tb-core/hooks/use-user-context';

const ImageErrorModal = dynamic(() =>
    import('@tb-core/components/container/image-error-modal')
);
const LoyaltyFirstTimeUserLegalModal = dynamic(() =>
    import('@tb-core/components/container/loyalty-modal/legal')
);
const LoyaltyFirstTimeUserRewardModal = dynamic(() =>
    import('@tb-core/components/container/loyalty-modal/reward')
);

const LoyaltyModals = () => {
    const { loyalty } = usePageContext().content.pageData.topicMeta;
    const user = useUserContext();
    return (
        <>
            {loyalty?.errorModal && (
                <ImageErrorModal id="loyalty-error-modal" />
            )}
            {loyalty?.firstTimeUserLegalModal && (
                <LoyaltyFirstTimeUserLegalModal
                    emailOptIn={user.isLoggedIn ? user.emailOptIn : undefined}
                    hideClose={true}
                    template={loyalty.firstTimeUserLegalModal}
                />
            )}
            {loyalty?.firstTimeUserRewardModal && (
                <LoyaltyFirstTimeUserRewardModal
                    template={loyalty.firstTimeUserRewardModal}
                />
            )}
        </>
    );
};

export default LoyaltyModals;
