import classNames from 'classnames';
import { useRouter } from 'next/router';
import { SyntheticEvent } from 'react';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import ResponsiveImage from '@tb-core/components/composites/responsive-image';
import BrandedLinkButton from '@tb-core/components/styled/links/branded-link-button';
import { ThemableLinkButtonProps } from '@tb-core/components/styled/links/themable-link-button';
import setModuleAmplitudeEvent from '@tb-core/components/styled/modules/helpers/set-module-amplitude-event';
import { optimizelyClient } from '@tb-core/helpers/analytics/optimizely';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import usePageContext from '@tb-core/hooks/use-page-context';
import useUserContext from '@tb-core/hooks/use-user-context';

import styles from './styles.module.scss';

export interface BrandedCtaGroupProps {
    ctaGroup: ThemableLinkButtonProps[];
}

type CTFButtonActions = () => void;

const BrandedCtaGroup = ({
    ctaGroup: [primary, secondary]
}: BrandedCtaGroupProps) => {
    const { isLoggedIn } = useUserContext();
    // https://legacy.reactjs.org/warnings/unknown-prop.html
    // fixes unknown prop warning
    // ECCONT-525: Update Contentful Link ID field to Action
    // linkId is named Action in Contentful but Field ID will still refer to linkId
    const { linkId, ...primaryProps } = primary;

    const { updateModals } = useLayoutActivityContext();
    const { topicMeta } = usePageContext().content.pageData;

    const pagePath = useRouter().asPath;

    // action to run based off of linkId from Contentful
    const ctaLinkIdActions: Record<string, CTFButtonActions> = {
        'check-gift-card-balance-modal': () => {
            updateModals({
                ['check-gift-card-balance-modal']: { ...topicMeta }
            });
        }
    };

    const handleClick = (
        event: SyntheticEvent,
        module: ThemableLinkButtonProps
    ) => {
        if (module.linkId && module.linkId in ctaLinkIdActions) {
            event.preventDefault();
            ctaLinkIdActions[module.linkId]();
        }
    };

    const trackModuleClick = (module: ThemableLinkButtonProps) => {
        setModuleAmplitudeEvent({ isLoggedIn, ...module }, pagePath);

        // must be merged with https://tacobeta.atlassian.net/browse/ECDISC-53
        // add optimizely flag from ECDISC-53 here when completed
        if (pagePath === '/' && module.gaDataLayerConfig) {
            const moduleType = module.gaDataLayerConfig['Analytics-Action'];

            if (moduleType === 'Bucket' || moduleType === 'Hero') {
                optimizelyClient.track(
                    `clicked_${moduleType.toLowerCase()}_module`
                );
            }
        }
    };

    return (
        <>
            {primary.graphic ? (
                <SafeAnchorWithGaEvent
                    className={classNames(styles['graphic-cta'], styles.narrow)}
                    gaDataLayerConfig={primary.gaDataLayerConfig}
                    href={primary.url}
                    onClick={e => {
                        handleClick(e, primary);
                        trackModuleClick(primary);
                    }}
                >
                    <ResponsiveImage {...primary.graphic.graphic} />
                </SafeAnchorWithGaEvent>
            ) : (
                <BrandedLinkButton
                    {...primaryProps}
                    className={styles['primary-cta']}
                    colorTheme={primary.colorTheme}
                    href={primary.url}
                    onClick={e => {
                        handleClick(e, primary);
                        trackModuleClick(primary);
                    }}
                >
                    {primary.label}
                </BrandedLinkButton>
            )}
            {secondary && (
                <>
                    {secondary.graphic ? (
                        <SafeAnchorWithGaEvent
                            className={classNames(
                                styles['graphic-cta'],
                                styles.narrow
                            )}
                            gaDataLayerConfig={secondary.gaDataLayerConfig}
                            href={secondary.url}
                            onClick={e => {
                                handleClick(e, secondary);
                                trackModuleClick(secondary);
                            }}
                        >
                            <ResponsiveImage {...secondary.graphic.graphic} />
                        </SafeAnchorWithGaEvent>
                    ) : (
                        <BrandedLinkButton
                            {...secondary}
                            className={classNames(
                                styles['primary-cta'],
                                styles.inverse
                            )}
                            colorTheme={secondary.colorTheme}
                            href={secondary.url}
                            inverse={true}
                            onClick={e => {
                                handleClick(e, secondary);
                                trackModuleClick(secondary);
                            }}
                        >
                            {secondary.label}
                        </BrandedLinkButton>
                    )}
                </>
            )}
        </>
    );
};

export default BrandedCtaGroup;
