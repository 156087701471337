import { isDayPartNow } from '@tb-core/helpers/daypart';
import { ColorThemeProps, RealObject } from '@tb-core/types';

import { applyThemeToEntries, moduleAnalyticsAdapter } from './';

interface ChidlItemAdapter {
    colorTheme: ColorThemeProps | null | undefined;
    dataLayerTemplate: RealObject | undefined;
    moduleContent: RealObject[];
    moduleProps?: RealObject;
    daypartsConfig?: RealObject;
}

/**
 * Given an entry collection, call the theme and analytics adapters
 */
export const childItemAdapter = ({
    colorTheme,
    dataLayerTemplate,
    moduleContent,
    moduleProps,
    daypartsConfig
}: ChidlItemAdapter) => {
    if (!moduleContent || moduleContent.length < 1) {
        return;
    }

    // merge the moduleProps into each item of moduleContent array
    if (moduleProps) {
        moduleContent.forEach((module, i) => {
            moduleContent[i] = { ...module, ...moduleProps };
        });
    }

    // filter moduleContent for daypart - return true if:
    // module daypart field is empty, or daypart active
    let result = moduleContent.filter(
        module =>
            !module.daypart ||
            !daypartsConfig ||
            isDayPartNow(module.daypart, daypartsConfig)
    );

    if (colorTheme) {
        result = applyThemeToEntries(result, colorTheme);
    }

    if (dataLayerTemplate) {
        result = moduleAnalyticsAdapter({
            data: result,
            dataLayerTemplate
        });
    }

    return result;
};
